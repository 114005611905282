import { ref } from 'lit-html/directives/ref.js';
import { html, TemplateResult } from 'lit-html';
import { property } from '@lit/reactive-element/decorators.js';

import { when } from 'lit/directives/when';
import { nothing } from 'lit';
import { HModal, phoenixCustomElement, PhoenixLightLitElement } from '@shoper/phoenix_design_system';
import { createRef, Ref } from 'lit/directives/ref';

@phoenixCustomElement('confirm-modal')
export class ConfirmModal extends PhoenixLightLitElement {
    @property({ type: Boolean, reflect: true })
    opened = false;

    @property({ type: String })
    title = '';

    @property({ type: String })
    confirmText = '';

    @property({ type: String })
    cancelText = '';

    private _modal: Ref<HModal> = createRef();

    public close() {
        this._modal.value?.close();
    }

    public render(): TemplateResult<1> {
        return html`
            ${when(
                this.opened,
                () => html` <h-modal
                    class="modal-wrapper_alert"
                    ${ref(this._modal)}
                    opened
                    @modal.closed="${() => {
                        this.dispatchEvent(new CustomEvent('cancel'));
                    }}"
                >
                    <h-modal-header>
                        <b>${this.title}</b>
                        <h-modal-close class="btn btn_icon">
                            <h-icon
                                icon-name="icon-x"
                                role="button"
                                size="xl"
                                clickable
                            ></h-icon>
                        </h-modal-close>
                    </h-modal-header>
                    <h-modal-body>${this.getSlot('default')}</h-modal-body>

                    <h-modal-footer>
                        <h-modal-close class="btn btn_secondary">${this.cancelText}</h-modal-close>
                        <button
                            @click="${() => {
                                this.dispatchEvent(new CustomEvent('confirm'));
                            }}"
                            class="btn btn_primary"
                        >
                            ${this.confirmText}
                        </button>
                    </h-modal-footer>
                </h-modal>`,
                () => nothing
            )}
        `;
    }
}
